import React, { useEffect } from "react";
import {
  faBriefcase,
  faCirclePlus,
  faKey,
  faPaw,
  faTag,
} from "@fortawesome/free-solid-svg-icons";
import Modal from "../components/Modal";
import Input from "../components/Input";
import Card from "../components/Card";
import { makeApiRequest, apiUrl } from "../api";
import { useAuth0 } from "@auth0/auth0-react";
import Loading from "../components/Loading";
import { ProfileData } from "./Profile";
import { toast } from "react-toastify";
import ReactGA from "react-ga4";

interface TagData {
  name: string;
  address: string;
  showPhoneNumber: boolean;
  showAddress: boolean;
  type: "pet" | "key" | "bag" | "other";
  notes: string;
  addressUuid?: string;
  phoneNumberUuid?: string;
}

interface Tag {
  uuid: string;
  uniqueCode: string;
  tagData: TagData;
}

export const getIconForType = (type: string) => {
  switch (type) {
    case "pet":
      return faPaw;
    case "key":
      return faKey;
    case "bag":
      return faBriefcase;
    case "other":
      return faTag;
    default:
      return faPaw;
  }
};

function Tags() {
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: "/tags", title: "Tags" });
  }, []);

  const selectedTagDefault = {
    uuid: "",
    tagData: {
      type: "pet",
    },
  } as Tag;

  const [activateNewModalIsOpen, setActivateNewModalIsOpen] =
    React.useState(false);
  const [selectedTag, setSelectedTag] = React.useState(selectedTagDefault);
  const [tagModalIsOpen, setTagModalIsOpen] = React.useState(false);
  const [tags, setTags] = React.useState([] as Tag[]);
  const [uniqueCode, setUniqueCode] = React.useState("");
  const [loading, setLoading] = React.useState(true);
  const [profileData, setProfileData] = React.useState({} as ProfileData);

  const { getAccessTokenSilently } = useAuth0();

  const getProfile = async () => {
    const token = await getAccessTokenSilently();
    const profileData = (
      await makeApiRequest("get", token, `${apiUrl}/profiles`)
    )?.profile;
    setProfileData(profileData ?? {});
  };

  const getTags = async (uniqueCode?: string) => {
    const token = await getAccessTokenSilently();
    const tags =
      (await makeApiRequest("get", token, `${apiUrl}/tags`))?.tags ?? [];
    setTags(tags);

    if (uniqueCode) {
      setSelectedTag(
        tags.find((t: Tag) => t.uniqueCode === uniqueCode) ?? selectedTagDefault
      );
    }
  };

  useEffect(() => {
    Promise.all([getTags(), getProfile()]).then(() => {
      setLoading(false);
    });
  }, []);

  const handleSelectedTagDataChange = (name: string, value: any) => {
    const newSelectedTagData = {
      ...selectedTag,
      tagData: {
        ...selectedTag.tagData,
        [name]: value,
      } as TagData,
    };
    setSelectedTag(newSelectedTagData);
  };

  return (
    <div className="flex flex-col w-full">
      {loading && !activateNewModalIsOpen && !tagModalIsOpen ? (
        <Loading />
      ) : (
        <>
          <p className="text-xl font-bold mb-4">My Tags</p>
          {tags.map((tag, k) => (
            <Card
              key={k}
              icon={getIconForType(tag.tagData?.type)}
              onClick={() => {
                setSelectedTag(tag);
                setTagModalIsOpen(true);
              }}
              inline
            >
              <>
                <p className="text-xl font-bold">{tag.tagData?.name}</p>

                <button className="px-4 py-2 bg-slate-950 text-slate-100 hover:bg-slate-700 rounded">
                  Edit
                </button>
              </>
            </Card>
          ))}

          <Card
            icon={faCirclePlus}
            onClick={() => {
              setActivateNewModalIsOpen(true);
            }}
            inline
          >
            <p className="text-xl font-bold">Activate a new tag</p>
          </Card>
        </>
      )}

      <Modal modalIsOpen={tagModalIsOpen}>
        <div className="overflow-y-auto max-h-128">
          <p className="text-xl font-bold mb-4">
            Update a tag{" "}
            <span className="text-xs">
              -{" "}
              <a
                className="underline"
                href={`${window.location.origin}/details/${selectedTag.uuid}`}
                target="_blank"
              >
                Preview The Page
              </a>
            </span>
          </p>
          <Input
            label={
              selectedTag.tagData?.type === "pet" ? "Pet Name" : "Tag Name"
            }
            name="name"
            value={selectedTag.tagData?.name}
            onChange={handleSelectedTagDataChange}
          />
          <Input
            type="select"
            label="Type"
            name="type"
            value={selectedTag.tagData?.type}
            onChange={handleSelectedTagDataChange}
            options={[
              { text: "Pet", value: "pet" },
              { text: "Key", value: "key" },
              { text: "Bag", value: "bag" },
              { text: "Other", value: "other" },
            ]}
          />
          <Input
            type="textarea"
            label="Notes"
            name="notes"
            value={selectedTag.tagData?.notes}
            onChange={handleSelectedTagDataChange}
          />
          <Input
            disabled={!profileData?.phoneNumbers?.length}
            label="Show Phone Number"
            name="showPhoneNumber"
            type="checkbox"
            value={
              !profileData?.phoneNumbers?.length
                ? false
                : selectedTag.tagData?.showPhoneNumber
            }
            extra={
              <div className="text-xl pl-2 w-full">
                {profileData?.phoneNumbers?.length ? (
                  <Input
                    disabled={!selectedTag.tagData?.showPhoneNumber}
                    name="phoneNumberUuid"
                    type="select"
                    value={selectedTag.tagData?.phoneNumberUuid}
                    options={[
                      { text: "Select a number...", value: null },
                      ...profileData.phoneNumbers.map((pN) => {
                        return { text: pN.name, value: pN.uuid };
                      }),
                    ]}
                    onChange={handleSelectedTagDataChange}
                  />
                ) : (
                  <span className="text-sm text-red-400">
                    Add a mobile number to your profile
                  </span>
                )}
              </div>
            }
            onChange={handleSelectedTagDataChange}
          />
          <Input
            disabled={!profileData?.addresses?.length}
            label="Show Address"
            name="showAddress"
            type="checkbox"
            value={
              !profileData?.addresses?.length
                ? false
                : selectedTag.tagData?.showAddress
            }
            extra={
              <div className="text-xl pl-2 w-full">
                {!profileData?.addresses?.length ? (
                  <span className="text-sm text-red-400">
                    Add an address to your profile
                  </span>
                ) : (
                  <Input
                    disabled={!selectedTag.tagData?.showAddress}
                    name="addressUuid"
                    value={selectedTag.tagData?.addressUuid}
                    type="select"
                    options={[
                      { text: "Select an address...", value: "" },
                      ...profileData.addresses?.map((a) => {
                        return { text: a.name, value: a.uuid };
                      }),
                    ]}
                    onChange={handleSelectedTagDataChange}
                  />
                )}
              </div>
            }
            onChange={handleSelectedTagDataChange}
          />

          <div className="flex flex-row justify-around pt-2">
            <button
              onClick={async () => {
                setSelectedTag(selectedTagDefault);
                setTagModalIsOpen(false);
              }}
              className="px-4 py-2 bg-white border-2 border-slate-950 hover:bg-slate-100 rounded w-28"
            >
              Close
            </button>
            <button
              onClick={async () => {
                if (loading) return;

                // TODO: Update these to real modals
                if (
                  selectedTag.tagData?.showAddress &&
                  !selectedTag.tagData?.addressUuid
                )
                  return alert("Select an address to show.");

                if (
                  selectedTag.tagData?.showPhoneNumber &&
                  !selectedTag.tagData?.phoneNumberUuid
                )
                  return alert("Select a phone number to show.");

                setLoading(true);

                const token = await getAccessTokenSilently();
                const data = await makeApiRequest(
                  "post",
                  token,
                  `${apiUrl}/tags/${selectedTag.uuid}`,
                  {
                    uniqueCode: selectedTag.uniqueCode,
                    tagData: selectedTag.tagData,
                  }
                );
                if (data.success) {
                  await getTags();
                  toast.success("Updated your tag details.");
                } else {
                  toast.error("An Error Occurred.");
                }
                setSelectedTag(selectedTagDefault);
                setLoading(false);
                setTagModalIsOpen(false);
              }}
              className="px-4 py-2 bg-slate-950 text-slate-100 hover:bg-slate-700 rounded border-2 border-slate-950 max-h-10 w-28"
            >
              {loading ? <Loading color="white" small /> : "Update"}
            </button>
          </div>
        </div>
      </Modal>

      <Modal modalIsOpen={activateNewModalIsOpen}>
        <p className="text-xl font-bold mb-4">Activate a new tag</p>
        <Input
          label="Activation Code"
          value={uniqueCode}
          onChange={(n: string, v: any) => setUniqueCode(v)}
        />
        <div className="flex flex-row justify-around pt-4">
          <button
            onClick={() => {
              setActivateNewModalIsOpen(false);
            }}
            className="px-4 py-2 bg-white border-2 border-slate-950 hover:bg-slate-100 rounded w-28"
          >
            Close
          </button>
          <button
            className="px-4 py-2 bg-slate-950 text-slate-100 hover:bg-slate-700 rounded border-2 border-slate-950 w-28"
            onClick={async () => {
              if (loading) return;
              setLoading(true);
              const token = await getAccessTokenSilently();
              const data = await makeApiRequest(
                "post",
                token,
                `${apiUrl}/tags/activate`,
                {
                  uniqueCode,
                }
              );
              setUniqueCode("");

              if (data.success) {
                await getTags(uniqueCode);
                setTagModalIsOpen(true);
                setActivateNewModalIsOpen(false);
                toast.success("Activated your new tag.");
              } else {
                toast.error("An Error Occurred.");
                setActivateNewModalIsOpen(false);
              }
              setLoading(false);
            }}
          >
            {loading ? <Loading color="white" small /> : "Activate"}
          </button>
        </div>
      </Modal>
    </div>
  );
}

export default Tags;
