import axios from "axios";
import { toast } from "react-toastify";

export const apiUrl =
  "https://zb5uaj9cee.execute-api.eu-west-1.amazonaws.com/prod";

export const buildAxiosConfig = (token: string) => {
  return {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
};

export const makeApiRequest = async (
  method: "get" | "post",
  token: string,
  apiUrl: string,
  data?: any,
  onError = () => {}
) => {
  try {
    const req =
      method === "get"
        ? axios.get(apiUrl, buildAxiosConfig(token))
        : axios.post(apiUrl, data, buildAxiosConfig(token));

    return (await req).data;
  } catch (error) {
    onError();
    toast.error("Error - Too many requests.");
    return false;
  }
};
