import React, { useEffect, useState } from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import "./App.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCartPlus,
  faClose,
  faBars,
  faIdCard,
  faRightFromBracket,
  faHome,
  faTags,
} from "@fortawesome/free-solid-svg-icons";
import { IconDefinition } from "@fortawesome/fontawesome-common-types";
import Pets from "./pages/Tags";
import Profile from "./pages/Profile";
import Purchase from "./pages/Purchase";
import TagDetails from "./pages/TagDetails";
import Home from "./pages/Home";
import { useAuth0 } from "@auth0/auth0-react";
import Loading from "./components/Loading";
import ReactGA from "react-ga4";

function App() {
  useEffect(() => {
    ReactGA.initialize("G-58MC9JYX0V");
  }, []);

  const [dropDownMenuOpen, setDropDownMenuOpen] = useState<any>(false);

  const { loginWithRedirect, logout, isAuthenticated, isLoading, user } =
    useAuth0();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (
      !isLoading &&
      !isAuthenticated &&
      location.pathname !== "/" &&
      location.pathname !== "/purchase" &&
      !location.pathname.includes("/details/")
    ) {
      loginWithRedirect();
    }
  }, []);

  useEffect(() => {
    setDropDownMenuOpen(false);
  }, [location]);

  const renderIcon = (icon: IconDefinition, onClick?: any) => (
    <div className="min-w-8">
      <FontAwesomeIcon
        className="cursor-pointer"
        icon={icon}
        size="2xl"
        color="#f1f5f9"
        onClick={onClick}
      />
    </div>
  );

  const renderDropDownMenuItem = (
    icon: IconDefinition,
    text: string,
    to?: string,
    callback?: Function
  ) => (
    <div
      className="flex flex-row items-center p-4 cursor-pointer"
      onClick={() => {
        if (callback) callback();
        if (to) navigate(to);
      }}
    >
      <FontAwesomeIcon icon={icon} size="xl" color="#170206" />
      <p className="text-xl text-slate-950 ml-4">{text}</p>
    </div>
  );

  return (
    <div className="h-full flex flex-col bg-gray-100 relative">
      <div className="flex flex-row items-center p-4 bg-slate-950 drop-shadow-lg justify-between fixed top-0 left-0 right-0">
        {isLoading ? (
          <div className="min-w-8" />
        ) : (
          <>
            {dropDownMenuOpen
              ? renderIcon(faClose, () => setDropDownMenuOpen(false))
              : renderIcon(faBars, () => setDropDownMenuOpen(true))}
          </>
        )}

        <div
          className="flex flex-col items-center cursor-pointer"
          onClick={() => navigate("/")}
        >
          {/* <img src={logo} width={30} /> */}
          <p className="text-gray-100 font-bold text-xl py-2">taginfo</p>
        </div>

        {isLoading ? (
          <div className="min-w-8" />
        ) : (
          <>
            {renderIcon(faCartPlus, () => {
              navigate("/purchase");
            })}
          </>
        )}

        {dropDownMenuOpen && (
          <div
            className="fixed bg-white w-full left-0 z-4"
            style={{ top: "76px" }}
          >
            <div className="flex flex-col">
              {isAuthenticated ? (
                <>
                  {renderDropDownMenuItem(faHome, "Home", "/")}
                  {renderDropDownMenuItem(faTags, "My Tags", "/tags")}
                  {renderDropDownMenuItem(faIdCard, "My Profile", "/profile")}
                  {renderDropDownMenuItem(faCartPlus, "Purchase", "/purchase")}
                  {renderDropDownMenuItem(
                    faRightFromBracket,
                    "Logout",
                    undefined,
                    () => logout()
                  )}
                </>
              ) : (
                <>
                  {renderDropDownMenuItem(faHome, "Home", "/")}
                  {renderDropDownMenuItem(faCartPlus, "Purchase", "/purchase")}
                  {renderDropDownMenuItem(
                    faRightFromBracket,
                    "Login",
                    undefined,
                    () => {
                      loginWithRedirect();
                    }
                  )}
                </>
              )}
            </div>
          </div>
        )}
      </div>

      <div className="pt-24 px-4 lg:flex lg:w-full lg:justify-center">
        {isLoading ? (
          <Loading />
        ) : (
          <div className="lg:flex lg:justify-center lg:w-2/4">
            <Routes>
              <Route path={"/tags"} element={<Pets />} />
              <Route path={"/details/:uuid"} element={<TagDetails />} />
              <Route path={"/profile"} element={<Profile />} />
              <Route path={"/purchase"} element={<Purchase />} />
              <Route path={""} element={<Home />} />
            </Routes>
          </div>
        )}
      </div>

      {/* {isAuthenticated && (
        <div className="fixed bg-black w-full p-2" style={{ bottom: "0" }}>
          <p className="text-white font-bold">Welcome, {user?.given_name}.</p>
        </div>
      )} */}
    </div>
  );
}

export default App;
