import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle, faTrashCan } from "@fortawesome/free-solid-svg-icons";
import Modal from "../components/Modal";
import { ListData } from "../pages/Profile";
import Input from "./Input";
import GoogleMap from "./GoogleMap";
import Loading from "./Loading";

function ModalList({
  items,
  name,
  handleUpdate,
  handleSave,
  handleDelete,
  updating,
}: {
  items?: ListData[];
  name: string;
  handleUpdate: Function;
  handleSave: Function;
  handleDelete: Function;
  updating: boolean;
}) {
  const [selectedItem, setSelectedItem] = React.useState({} as ListData);
  const [selectedItemKey, setSelectedItemKey] = React.useState(
    false as false | number
  );
  const [updateModalOpen, setUpdateModalOpen] = React.useState(false);
  const [addModalOpen, setAddModalOpen] = React.useState(false);

  const handleChange = (name: string, value: any) => {
    const newSelectedItem = {
      ...selectedItem,
      ...{ [name]: value },
    };
    setSelectedItem(newSelectedItem);
  };

  return (
    <>
      <div className="flex flex-col rounded bg-white shadow-lg w-full">
        {items?.map((item, key) => (
          <div className="flex flex-row justify-between shadow w-full p-2 items-center">
            <p className="text-xl font-bold ">{item.name}</p>
            <div className="flex flex-row justify-around items-center">
              <FontAwesomeIcon
                icon={faTrashCan}
                size="2x"
                color="#170206"
                className="mr-4 cursor-pointer"
                onClick={() => {
                  if (
                    // TODO: update to a proper modal
                    window.confirm(
                      `Are you sure you want to delete this ${name.toLocaleLowerCase()}?`
                    )
                  ) {
                    handleDelete(item.uuid);
                  }
                }}
              />
              <button
                className="px-4 py-2 bg-slate-950 text-slate-100 hover:bg-slate-700 rounded"
                onClick={() => {
                  setSelectedItemKey(key);
                  setSelectedItem(item);
                  setUpdateModalOpen(true);
                }}
              >
                Edit
              </button>
            </div>
          </div>
        ))}
      </div>
      <button
        className="text-xl font-bold p-2 cursor-pointer hover:bg-gray-200 align-middle text-left border-dashed border-gray-300 border-2 rounded mt-2 mb-4"
        onClick={() => {
          setAddModalOpen(true);
        }}
      >
        <span className="mr-2">
          <FontAwesomeIcon icon={faPlusCircle} size="lg" color="#170206" />
        </span>
        Add {`${name === "Address" ? "an Address" : "a Phone Number"}`}
      </button>

      <Modal modalIsOpen={addModalOpen || updateModalOpen}>
        <p className="text-xl font-bold mb-4">
          {updateModalOpen ? "Update" : "Add a new"} {name.toLowerCase()}
        </p>

        <Input
          name="name"
          label="Name"
          value={selectedItem.name}
          onChange={handleChange}
        />

        <Input
          name="value"
          label={name}
          value={selectedItem.value}
          onChange={handleChange}
          type={name === "Address" ? "textarea" : "tel"}
          pattern="[\+]\d{2}[\(]\d{2}[\)]\d{4}[\-]\d{4}"
        />

        {name === "Address" && selectedItem.value && (
          <>
            <p className="font-bold mt-2 mb-2 pl-1 underline">
              Map that will be shown:
            </p>
            <GoogleMap address={selectedItem.value} />
          </>
        )}

        <div className="flex flex-row justify-around pt-4">
          <button
            onClick={() => {
              setAddModalOpen(false);
              setUpdateModalOpen(false);
              setSelectedItem({} as ListData);
              setSelectedItemKey(false);
            }}
            className="px-4 py-2 bg-white border-2 border-slate-950 hover:bg-slate-100 rounded w-28 mr-2"
          >
            Close
          </button>
          <button
            disabled={updating}
            className="px-4 py-2 bg-slate-950 text-slate-100 hover:bg-slate-700 rounded border-2 border-slate-950 w-28 ml-2"
            onClick={async () => {
              // TODO: update this and add real validation
              if (!selectedItem.name) return alert("Add a name");
              if (!selectedItem.value)
                return alert(
                  `Add ${name === "Address" ? "an address" : "a phone number"}`
                );

              if (updating) return;
              if (updateModalOpen) {
                await handleUpdate(selectedItemKey, selectedItem);
                setUpdateModalOpen(false);
              } else {
                await handleSave(selectedItem);
                setAddModalOpen(false);
              }
              setSelectedItem({} as ListData);
              setSelectedItemKey(false);
            }}
          >
            {updating ? (
              <Loading color="white" small />
            ) : updateModalOpen ? (
              "Update"
            ) : (
              "Save"
            )}
          </button>
        </div>
      </Modal>
    </>
  );
}

export default ModalList;
