import axios from "axios";
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import GoogleMap from "../components/GoogleMap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getIconForType } from "./Tags";
import Loading from "../components/Loading";
import { apiUrl } from "../api";
import ReactGA from "react-ga4";

function TagDetails() {
  const { uuid } = useParams();
  const [tagData, setTagData] = React.useState({} as any);
  const [loading, setLoading] = React.useState(true);

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: `/tags/${uuid}`,
      title: "Tag Details",
    });
  }, []);

  const getTagData = async () => {
    const res = await axios.get(`${apiUrl}/tags/${uuid}`);
    setLoading(false);
    return setTagData(res.data.tag);
  };

  useEffect(() => {
    getTagData();
  }, []);

  return (
    <div className="flex flex-col items-center">
      {loading ? (
        <Loading />
      ) : (
        <div>
          <p className="text-xl font-bold mb-4">
            Details{" "}
            <span>
              <FontAwesomeIcon icon={getIconForType(tagData.type)} />
            </span>
          </p>

          <p>
            <span className="font-bold">owner: </span>
            {tagData?.owner}
          </p>
          {tagData?.phoneNumber && (
            <p>
              <span className="font-bold">phone number: </span>{" "}
              {tagData.phoneNumber}
            </p>
          )}

          <p className="mt-4">
            <span className="font-bold">
              {tagData.type === "pet" ? "pets name" : "tag name"}:{" "}
            </span>{" "}
            {tagData?.name}
          </p>
          {tagData?.notes && tagData?.notes !== "" && (
            <p>
              <span className="font-bold">notes: </span> {tagData?.notes}
            </p>
          )}
          {tagData?.address && (
            <>
              <span className="font-bold">address map:</span>
              <div className="">
                <GoogleMap address={tagData.address} />
              </div>
            </>
          )}
        </div>
      )}
    </div>
  );
}

export default TagDetails;
