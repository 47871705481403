function GoogleMap({ address }: { address?: string }) {
  return (
    <iframe
      width="300"
      height="300"
      loading="lazy"
      referrerPolicy="no-referrer-when-downgrade"
      src={`https://www.google.com/maps/embed/v1/place?key=${`AIzaSyDT6Tc7KV79EYenqQ3Zkax-PMNQuJ-qnoo`}
&q=${address}`}
    ></iframe>
  );
}

export default GoogleMap;
