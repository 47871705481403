import React from "react";
import { IconDefinition, faPaw } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function Card({
  icon,
  children,
  onClick,
  inline,
  classes,
}: {
  icon?: IconDefinition;
  children: any;
  onClick?: () => void;
  inline?: boolean;
  classes?: string;
}) {
  if (inline) {
    return (
      <div
        className="flex flex-row rounded bg-white shadow-lg mb-4 cursor-pointer hover:bg-slate-100"
        onClick={onClick}
      >
        <div className="border-r-2 border-slate-950 h-full p-4 mr-4">
          <div className="min-w-8">
            <FontAwesomeIcon icon={icon ?? faPaw} size="2xl" color="#170206" />
          </div>
        </div>
        <div className="flex flex-row justify-between items-center w-full pr-4">
          {children}
        </div>
      </div>
    );
  }

  return (
    <div
      className="flex flex-col rounded bg-white shadow-lg mb-4 p-2"
      onClick={onClick}
    >
      {children}
    </div>
  );
}

export default Card;
