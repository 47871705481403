import React from "react";

function Input({
  options,
  placeholder,
  wrapperWidth,
  disabled,
  name,
  label,
  type,
  value,
  extra,
  classes,
  onChange,
  width,
  noPadding,
  style,
  pattern,
}: {
  options?: { text: any; value: any }[];
  wrapperWidth?: number;
  disabled?: boolean;
  placeholder?: string;
  name?: string;
  label?: string;
  type?: "password" | "textarea" | "checkbox" | "number" | "select" | "tel";
  value?: any;
  extra?: any;
  classes?: string;
  onChange?: any;
  width?: number;
  noPadding?: boolean;
  style?: any;
  pattern?: string;
}) {
  const handleChange = (e: any) =>
    onChange(
      e.target.name,
      type === "checkbox" ? e.target.checked : e.target.value
    );

  let input = (
    <input
      disabled={disabled}
      className={`${
        noPadding ? "" : "py-2 px-4"
      } border-2 border-slate-100 rounded text-xl ${
        type === "checkbox" || width ? "" : "w-full"
      }${width ? `w-${width}` : ""} ${
        disabled ? "bg-gray-200 cursor-not-allowed text-gray-400" : ""
      } ${classes ? classes : ""}`}
      type={type}
      value={value}
      name={name}
      placeholder={placeholder}
      checked={value}
      onChange={handleChange}
      style={style}
      pattern={pattern}
    />
  );

  if (type === "select") {
    input = (
      <select
        disabled={disabled}
        name={name}
        className={`w-full py-2 px-4 border-2 border-slate-100 rounded text-xl ${
          disabled ? "disabled" : ""
        }`}
        onChange={handleChange}
      >
        {options?.map((o) => (
          <option value={o.value} selected={o.value === value}>
            {o.text}
          </option>
        ))}
      </select>
    );
  }

  if (type === "textarea") {
    input = (
      <textarea
        placeholder={placeholder}
        disabled={disabled}
        className={`w-full ${
          noPadding ? "" : "py-2 px-4"
        } border-2 border-slate-100 rounded text-xl`}
        value={value}
        onChange={handleChange}
        style={style}
        name={name}
      />
    );
  }

  if (!label) {
    return input;
  }

  return (
    <div
      className={`p-2 ${wrapperWidth ? "" : "flex-1 w-full"} `}
      style={{ width: wrapperWidth ? `${wrapperWidth}%` : "100%" }}
    >
      <label className="text-l font-bold mb-4 text-slate-500">{label}</label>
      <div className="flex flex-row items-center">
        {input} {extra}
      </div>
    </div>
  );
}

export default Input;
